import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Cta from '../components/cta'
import StorageProducts from '../components/storageProducts'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  {post.frontmatter.bodytitle}
                </h2>
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </section>

      {post.frontmatter.section &&
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          {post.frontmatter.section.sort((a, b) => a.sort == b.sort ? 0 : a.sort < b.sort ? -1 : 1).map((sections, index) => (
            <StorageProducts key={index} id={index} platform={sections} />
          ))}
        </MDBAnimation>
      }

      <section className="bg-blue-green-gradient">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <Cta
              ctatitle={post.frontmatter.cta.title}
              ctadescription={post.frontmatter.cta.description}
              ctalink={post.frontmatter.cta.link}
              ctalinktext={post.frontmatter.cta.linktext}
            />
          </MDBContainer>
        </MDBAnimation>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "storage" } }
    ) {
      fields {
        slug
      }
      html
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        bodytitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        section {
          title
          sort
          description
          image {
            childImageSharp {
              fluid(maxWidth: 650, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
          sectionproduct {
            subtitle
            text
          }          
          btntext
          btnlink
          doctext
          document {
            publicURL
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  }
`
